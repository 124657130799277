<template>
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
    :click-to-close="false"
  >
    <button class="modal__close btn" @click="closeModal">
      <i class="bi bi-x"></i>
    </button>
    <span class="modal__title">{{ formTitle }}</span>
    <div class="modal__content py-2 px-2">
      <form @submit.prevent="submitForm" class="form">
        <div class="col-md-12 mb-3">
          <label for="name" class="form-label">Nama</label>
          <input
            class="form-control"
            type="text"
            v-model="editedItem.name"
            :class="{ 'is-invalid': $v.editedItem.name.$error }"
          />
          <div v-if="$v.editedItem.name.$error" class="invalid-feedback">
            <span v-if="!$v.editedItem.name.required">Nama harus di isi.</span>
          </div>
        </div>
        <div class="col-md-12 mb-3">
          <label for="username" class="form-label">Username</label>
          <input
            class="form-control"
            type="text"
            v-model="editedItem.username"
          />
        </div>
        <div class="col-md-12 mb-3">
          <label for="email" class="form-label">Email</label>
          <input
            class="form-control"
            type="text"
            v-model="editedItem.email"
            :class="{ 'is-invalid': $v.editedItem.email.$error }"
          />
          <div v-if="$v.editedItem.email.$error" class="invalid-feedback">
            <span v-if="!$v.editedItem.email.required"
              >Email harus di isi.</span
            >
          </div>
        </div>
        <div class="col-md-12 mb-3">
          <label for="role" class="form-label">Role</label>
          <select class="form-select" v-model="editedItem.role">
            <option value="">Pilih...</option>
            <option v-for="option in roles" :key="option">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="col-md-12 mb-3">
          <label for="propinsi_id" class="form-label">Wilayah Provinsi</label>
          <v-select
            v-model="editedItem.propinsi_id"
            :options="propinsi"
            :reduce="(propinsi) => propinsi.id"
            label="nama"
            @input="onProvinceChange"
          >
          </v-select>
        </div>
        <div class="col-md-12 mb-3" v-if="editedItem.role === 'PAC' || editedItem.role === 'DPC'">
          <label for="propinsi_id" class="form-label">Wilayah Kota</label>
          <v-select
            v-model="editedItem.kota_id"
            :options="kota"
            :reduce="(kota) => kota.id"
            label="nama"
            @input="onKotaChange"
          >
          </v-select>
        </div>
        <div class="col-md-12 mb-3" v-if="editedItem.role === 'PAC'">
          <label for="propinsi_id" class="form-label">Wilayah Kecamatan</label>
          <v-select
            v-model="editedItem.kecamatan_id"
            :options="kecamatan"
            :reduce="(kecamatan) => kecamatan.id"
            label="nama"
          >
          </v-select>
        </div>

        <div class="col-md-12 mb-3" v-if="editedIndex === -1">
          <label for="password" class="form-label">Password</label>
          <input
            class="form-control"
            type="password"
            v-model="editedItem.password"
            :class="{ 'is-invalid': $v.editedItem.password.$error }"
          />
          <div v-if="$v.editedItem.password.$error" class="invalid-feedback">
            <span v-if="!$v.editedItem.password.required"
              >Password harus di isi.</span
            >
          </div>
        </div>
        <div class="col-md-12 mb-3" v-if="editedIndex === -1">
          <label for="passwordConfirmation" class="form-label"
            >Password Confirmation</label
          >
          <input
            class="form-control"
            type="password"
            v-model="editedItem.password_confirmation"
            :class="{
              'is-invalid': $v.editedItem.password_confirmation.$error,
            }"
          />
          <div
            v-if="$v.editedItem.password_confirmation.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.editedItem.password_confirmation.required"
              >Konfirmasi Password harus di isi.</span
            >
          </div>
        </div>
      </form>
    </div>
    <div class="modal__action mt-3">
      <button class="btn btn-primary mx-2" @click="submitForm">Simpan</button>
      <button class="btn" @click="closeModal">Batal</button>
    </div>
  </vue-final-modal>
</template>

<script>
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { VueFinalModal } from "vue-final-modal";
export default {
  components: {
    VueFinalModal,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    editedIndex: {
      type: Number,
      default: -1,
    },
    editedId: {
      type: Number,
      default: -1,
    },
    editedItem: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
    propinsi: {
      type: Array,
      required: true,
    },
    kota: {
      type: Array,
      required: true,
    },
    kecamatan: {
      type: Array,
      required: true,
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Data Baru" : "Edit Data";
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    onProvinceChange(propinsi_id) {
      this.$emit("onProvinceChange", propinsi_id);
    },
    onKotaChange(kota_id) {
      this.$emit("onKotaChange", kota_id);
    },
    async submitForm() {
      this.$emit("save");
    },
  },
  validations: {
    editedItem: {
      name: { required },
      username: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) },
      password_confirmation: { required, sameAsPassword: sameAs("password") },
      role: {
        required,
      },
    },
  },
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  max-width: 600px;
  max-height: 600px;
  overflow-y: auto;
}

.modal__content {
  max-height: 600px;
  overflow-y: auto;
}
</style>
