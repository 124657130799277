<template>
  <div class="users">
    <h1 class="mb-5">Data Pengguna</h1>
    <div class="row">
      <!-- Card -->
      <div class="col-md-12">
        <div class="card">
          <h5 class="
              card-header
              d-flex
              justify-content-between
              align-items-center
            ">
            Daftar Pengguna
            <button @click="showFormModal = true" class="btn btn-sm btn-primary">
              <i class="bi bi-plus-circle"></i> Tambah Pengguna
            </button>
          </h5>
          <div class="card-body table-responsive">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Cari" aria-label="Cari"
                aria-describedby="button-addon2" v-model="search" @keypress.enter="searchData" />
              <button class="btn btn-outline-secondary" type="button" @click="searchData">
                Cari
              </button>
            </div>
            <transition name="fade">
              <div class="text-center" v-if="isLoading">
                <div class="spinner-grow" role="status"></div>
              </div>
              <Table v-else :headers="headers" :items="users" @edit="editItem" @delete="confirmDelete" />
            </transition>
            <Pagination v-if="users.length" :currentPage="currentPage" :totalData="totalData" :totalPage="totalPage"
              @nextPage="nextPage" @previousPage="previousPage" />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal konfirmasi hapus -->
    <delete-modal :showModal="showDeleteModal" :id="selectedUserId" @closeModal="closeDeleteModal"
      @destroy="destroyUser" />

    <!-- Modal tambah/edit -->
    <form-modal :showModal="showFormModal" :editedIndex="editedIndex" :editedId="editedId" :editedItem="editedItem"
      :roles="roles" :propinsi="propinsi" :kota="kota" :kecamatan="kecamatan" @closeModal="closeForm"
      @onProvinceChange="onProvinceChange" @onKotaChange="onKotaChange" @save="saveForm" />
  </div>
</template>

<script>
import {
  userService,
  provinsiService,
  kotaService,
  kecamatanService,
} from "@/services";
import DeleteModal from "./components/DeleteModal.vue";
import FormModal from "./components/FormModal.vue";
import Table from "./components/Table.vue";
import Pagination from "./components/Pagination.vue";

export default {
  components: {
    Table,
    FormModal,
    DeleteModal,
    Pagination,
  },
  data() {
    return {
      // Search query
      search: "",
      limit: 10,
      sort: "updatedAt",
      order: "desc",

      // Pagination
      totalData: 0,
      currentPage: 1,

      // Loading State
      isLoading: false,

      // Data Array
      headers: [
        "Nama",
        "Email",
        "Role",
        "Tanggal Dibuat",
        "Tanggal Diubah",
        "Tindakan",
      ],
      users: [],
      roles: ["Anggota", "Admin", "PAC", "DPC"],
      propinsi: [],
      kota: [],
      kecamatan: [],

      // Modal state
      showDeleteModal: false,
      showFormModal: false,
      selectedUserId: 0,

      // Default form state
      editedIndex: -1,
      editedId: 0,
      editedItem: {
        name: "",
        username: "",
        email: "",
        role: "",
        password: "",
        password_confirmation: "",
        propinsi_id: null,
        kota_id: null,
        kecamatan_id: null,
      },
      defaultItem: {
        name: "",
        username: "",
        email: "",
        role: "",
        password: "",
        password_confirmation: "",
        propinsi_id: null,
        kota_id: null,
        kecamatan_id: null,
      },
    };
  },
  computed: {
    totalPage() {
      return Math.ceil(this.totalData / this.limit);
    },
  },
  methods: {
    // Search & Load
    searchData() {
      this.currentPage = 1;
      this.isLoading = true;
      this.loadData(
        this.limit,
        this.search,
        this.currentPage,
        this.sort,
        this.order
      );
    },
    loadData(limit, search, page, sort, order) {
      userService.getAll(limit, search, page, sort, order).then((data) => {
        this.users = data.rows;
        this.totalData = data.count;
        this.currentPage = page;
      });
      provinsiService.getAll(1000, "", 1).then((resp) => {
        this.propinsi = resp.rows;
      });
      this.isLoading = false;
    },
    onProvinceChange(propinsi_id) {
      kotaService.getAll("", "", "", "", "", propinsi_id).then((resp) => {
        this.kota = resp.rows;
      });
    },
    onKotaChange(kota_id) {
      kecamatanService.getAll("", "", "", "", "", kota_id).then((resp) => {
        this.kecamatan = resp.rows;
      });
    },

    // Pagination
    previousPage() {
      this.loadData(
        this.limit,
        this.search,
        this.currentPage - 1,
        this.sort,
        this.order
      );
    },
    nextPage() {
      this.loadData(
        this.limit,
        this.search,
        this.currentPage + 1,
        this.sort,
        this.order
      );
    },

    // Modal
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.onProvinceChange(this.editedItem.propinsi_id);
      if (this.editedItem.role === "PAC") {
        this.onKotaChange(this.editedItem.kota_id);
      }
      this.showFormModal = true;
    },
    async saveForm() {
      const { name, username, email, role, propinsi_id, kota_id, kecamatan_id } = this.editedItem;
      if (this.editedIndex > -1) {
        // Update data
        try {
          await userService.update(
            { name, username, email, role, propinsi_id, kota_id, kecamatan_id },
            this.editedItem.id
          );
          this.closeForm();
          await this.searchData();
        } catch (error) {
          this.$toast.error(
            (error.response && error.response.data.errors[0].message) ||
            error.message
          );
        }
      } else {
        // Insert new data
        try {
          await userService.create(this.editedItem);
          this.closeForm();
          await this.searchData();
        } catch (error) {
          this.$toast.error(
            (error.response && error.response.data.errors[0].message) ||
            error.message
          );
        }
      }
    },
    closeForm() {
      this.showFormModal = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    confirmDelete(id) {
      this.showDeleteModal = true;
      this.selectedUserId = id;
    },
    destroyUser(id) {
      userService
        .destroy(id)
        .then(() => {
          this.$toast.success(`Pengguna berhasil dihapus`);
          this.showDeleteModal = false;
          this.selectedUserId = 0;
          this.searchData();
        })
        .catch((error) => {
          this.$toast.error(
            (error.response && error.response.data.message) || error.message
          );
        });
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadData(
      this.limit,
      this.search,
      this.currentPage,
      this.sort,
      this.order
    );
  },
};
</script>

<style scoped>
.users {
  padding: 3rem 1.5rem;
}
</style>
